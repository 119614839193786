import * as React from "react";
import ReactDOM from "react-dom/client";
import {
  createHashRouter,
  useMatch,
  useParams,
  Outlet,
  RouterProvider,
  Link,
} from "react-router-dom";

import * as Y from "yjs";
import useYProvider from "y-partykit/react";

import { Project } from "./Project";

const Root = () => {
  const m = useMatch("/");
  return (
    <div className="bg-slate-300">
      {m ? (
        <div>
          <Link to={"projects/foo"}>Foo</Link>
        </div>
      ) : (
        <Outlet />
      )}
    </div>
  );
};

type ProjectParams = { projectId: string };
const ProjectContainer = () => {
  const { projectId } = useParams<ProjectParams>();
  const ydoc = React.useRef(new Y.Doc());
  const provider = useYProvider({
    host: PARTYKIT_HOST,
    room: projectId!,
    doc: ydoc.current,
    options: {},
  });
  provider.connect();
  provider.on("synced", () => {
    console.log("synced");
  });
  (window as any).ydoc = ydoc;

  return <Project projectId={projectId!} ydoc={ydoc.current} />;
};

const Basic = () => {
  const { basicId } = useParams<{ basicId: string }>();
  const ydoc = React.useRef(new Y.Doc());
  const provider = useYProvider({
    host: PARTYKIT_HOST,
    party: "basic",
    room: basicId!,
    doc: ydoc.current,
    options: {},
  });
  //provider.connect()

  const ymessages = ydoc.current.getArray<string>("messages");
  const [messages, setMessages] = React.useState<string[]>(ymessages.toArray());
  const observerRef = React.useRef<any>(null);
  React.useEffect(() => {
    if (observerRef.current) {
      return;
    }
    observerRef.current = () => {
      setMessages(ymessages.toArray());
    };
    ymessages.observe(observerRef.current);
    return () => {
      ymessages.unobserve(observerRef.current);
      observerRef.current = null;
    };
  }, [setMessages, ymessages, observerRef]);
  return (
    <div>
      <button onClick={() => ymessages.insert(0, [`hey ${new Date()}`])}>
        Go
      </button>
      <div>
        {messages.map((m, i) => (
          <div key={i}>{m}</div>
        ))}
      </div>
    </div>
  );
};

const router = createHashRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "projects/:projectId",
        element: <ProjectContainer />,
      },
      { path: "basic/:basicId", element: <Basic /> },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("app")!).render(
  <RouterProvider router={router} />
);
