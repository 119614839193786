import React from "react";
import * as Y from "yjs";

import PartySocket from "partysocket";

// import markdown renderer for react
import Markdown from "react-markdown";

import { OpenAI } from "openai";

type Message = OpenAI.Beta.Threads.ThreadMessage & {
  metadata: { uuid: string };
};
type Run = OpenAI.Beta.Threads.Run & { metadata: { uuid: string } };

const PageInitialRenderContext = React.createContext(true);

function makeUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    // eslint-disable-next-line
    const r = (Math.random() * 16) | 0,
      // eslint-disable-next-line
      v = c == "x" ? r : (r & 0x3) | 0x8;
    // eslint-disable-next-line
    return v.toString(16);
  });
}

function WithPageInitialRenderContext({
  children,
}: {
  children: React.ReactNode;
}) {
  const [initialRender, setInitialRender] = React.useState(true);
  React.useEffect(() => {
    // should really do this after the initial yjs sync
    setTimeout(() => setInitialRender(false), 1000);
  }, []);
  return (
    <PageInitialRenderContext.Provider value={initialRender}>
      {children}
    </PageInitialRenderContext.Provider>
  );
}

function useEntryTransition(
  initial: string,
  final: string,
  disable: boolean = false,
  delay: number = 200
) {
  const initialRender = React.useContext(PageInitialRenderContext);
  const [classExtra, setClassExtra] = React.useState(
    initialRender ? final : initial
  );
  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    if (!(disable || initialRender || timeout.current)) {
      timeout.current = setTimeout(() => setClassExtra(final), delay);
    }
  }, [timeout, disable]);

  return classExtra;
}

const ListItem = ({ children }: { children: React.ReactNode }) => {
  const classExtra = useEntryTransition(
    "bg-green-200 duration-1000",
    "",
    false,
    1000
  );
  return (
    <div className={`mb-4 transition ease-in ${classExtra}`}>
      <input type="checkbox" />
      <label className="ml-2">{children}</label>
    </div>
  );
};

type ChatState =
  | {
      status: "idle";
    }
  | {
      status: "starting";
      uuid: string;
      message: string;
    }
  | {
      status: "running";
      uuid: string;
      message: string;
    }
  | { status: "usingTool"; uuid: string; message: string };

export const Project = ({
  projectId,
  ydoc,
}: {
  projectId: string;
  ydoc: Y.Doc;
}) => {
  const chatObserver = React.useRef<any>(null);
  const chatRef = React.useRef<HTMLDivElement>(null);
  const [messages, setMessages] = React.useState<Message[]>(
    (ydoc.getArray("chatLog")?.toArray() ?? []) as any
  );

  const [chatState, setChatState] = React.useState<ChatState>({
    status: "idle",
  });

  const ytodos = ydoc
    .getMap("projectData")
    .get("todoList") as Y.Array<string> | null;
  const todoObserver = React.useRef<any>(null);
  const [todos, setTodos] = React.useState(ytodos?.toArray() ?? []);

  const [messageInput, setMessageInput] = React.useState("");

  React.useEffect(() => {
    if (chatObserver.current) {
      return;
    }
    const chatLog = ydoc.getArray("chatLog");
    chatObserver.current = (event: any) => {
      setMessages(chatLog.toArray() as any);
    };
    chatLog.observe(chatObserver.current);
    return () => {
      if (chatObserver.current) {
        chatLog.unobserve(chatObserver.current);
        chatObserver.current = null;
      }
    };
  }, [ydoc, setMessages]);

  React.useEffect(() => {
    switch (chatState.status) {
      case "starting":
        const messageRecieved = messages.find(
          (m) => m.metadata.uuid === chatState.uuid
        );
        if (messageRecieved) {
          setChatState({
            status: "running",
            uuid: chatState.uuid,
            message: messageRecieved.content
              .map((c) => (c.type == "text" ? c.text : ""))
              .join("\n"),
          });
        }
        break;
      default:
        break;
    }
  }, [setChatState, chatState, messages]);

  const yruns = ydoc.getMap<Run>("runs");
  const runObserver = React.useRef<any>(null);
  React.useEffect(() => {
    if (runObserver.current) {
      return;
    }
    runObserver.current = (event: Y.YMapEvent<Run>) => {
      console.log(event.changes);
      if (event.changes) {
        const change = event.changes.keys.entries().next().value;
        console.log(change);
        setChatState({ status: "idle" });
      }
    };
    yruns.observe(runObserver.current);
    return () => {
      yruns.unobserve(runObserver.current);
      runObserver.current = null;
    };
  }, [yruns, runObserver, setChatState]);

  React.useEffect(() => {
    if (todoObserver.current || !ytodos) {
      return;
    }
    setTodos(ytodos.toArray());
    todoObserver.current = () => {
      setTodos(ytodos.toArray());
    };
    ytodos.observe(todoObserver.current);
    return () => {
      if (todoObserver.current) {
        ytodos.unobserve(todoObserver.current);
        todoObserver.current = null;
      }
    };
  }, [ytodos, setTodos]);

  React.useEffect(() => {
    // scroll to bottom of chat when messages change
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);
  return (
    <WithPageInitialRenderContext>
      <div className="flex h-screen bg-stone-200">
        <div className="flex flex-col w-1/2 p-4">
          <h2 className="text-2xl font-bold mb-4">Chat</h2>
          <div
            ref={chatRef}
            className="flex-1 bg-stone-50 px-4 rounded overflow-y-auto divide-y"
          >
            {messages.map((m: Message) => {
              return (
                <div key={m.id} className="pt-2 mb-4 space-y-4">
                  <h3 className="font-bold text-stone-700">{m.role}</h3>
                  {m.content.map((contentPiece: any, i: number) => (
                    <Markdown key={m.id + i}>
                      {contentPiece.text.value}
                    </Markdown>
                  ))}
                </div>
              );
            })}
            {chatState.status === "starting" &&
              !messages.find((m) => m.metadata.uuid == chatState.uuid) && (
                <div key={chatState.uuid} className="pt-2 mb-4 space-y-4">
                  <h3 className="font-bold text-stone-700">user</h3>
                  <Markdown>{chatState.message}</Markdown>
                </div>
              )}
          </div>
          <div className="mt-4">
            <input
              className="w-full p-2 border rounded"
              placeholder="Type a message"
              value={messageInput}
              onChange={(e) => setMessageInput(e.currentTarget.value)}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  const uuid = makeUUID();
                  PartySocket.fetch(
                    { host: PARTYKIT_HOST, room: projectId },
                    {
                      method: "POST",
                      body: JSON.stringify({ content: messageInput, uuid }),
                    }
                  );
                  setMessageInput("");
                  setChatState({
                    status: "starting",
                    uuid,
                    message: messageInput,
                  });
                }
              }}
            />
          </div>
        </div>
        <div className="flex flex-col w-1/2 p-4">
          <h2 className="text-2xl font-bold mb-4">Todo List</h2>
          <div className="flex-1 bg-stone-50 p-4 rounded overflow-y-auto">
            {todos.map((t) => (
              <ListItem key={t}>{t}</ListItem>
            ))}
          </div>
          <h2 className="text-2xl font-bold mb-4 mt-4">Summary</h2>
          <div className="bg-stone-50 p-4 rounded">
            <p>Placeholder summary</p>
          </div>
        </div>
      </div>
    </WithPageInitialRenderContext>
  );
};
